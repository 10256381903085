<template>
  <b-card>
    <base-info
      :geturl="dataurl"
      :fields-title="fieldsTitle"
      :fields-content="fields"
      :baseroute="baseroute"
      :isdelete="false"
      title="Info Sumbu"
      ref="baseinfo"
      @readData="showData"
    >
      <template v-slot:buttonaction>
        <b-button
          v-if="approval_status == 0 && setting == true"
          class="mr-1"
          size="sm"
          variant="success"
          type="button"
          @click.prevent="confirmApproval(true)"
        >
          <span>Setujui</span>
        </b-button>

        <b-button
          v-if="approval_status == 0 && setting == true"
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="confirmApproval(false)"
        >
          <span>Tolak</span>
        </b-button>

        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          type="button"
          @click.prevent="confirmBlokir"
        >
          <span>Blokir Bengkel</span>
        </b-button>
      </template>
    </base-info>
  </b-card>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfoV2.vue'

export default {
  components: {
    BCard,
    BaseInfo,
    BButton,
  },

  data() {
    const settings = this.$store.state.profile.info.settings
    var setting = null
    if(settings){
      settings.filter(value => value.code == 'workshop.add')
      setting = setting.length > 0 ? setting[0].approval : false
    }

    return {
      dataurl: '/workshop',
      baseroute: 'workshop',
      fieldsTitle: [
        { key: 'name', is_title: true },
        { key: 'postal_code' },
        {
          key: 'is_active',
          type: 'span',
          data: { true: 'Terdaftar', false: 'Pengajuan' },
        },
      ],
      fields: [
        { key: 'address', label: 'Alamat', icon: 'CompassIcon' },
        { key: 'phone', label: 'Handphone', icon: 'PhoneIcon' },
        { key: 'email', label: 'Email', icon: 'MailIcon' },
        { key: 'hr' },
        { key: 'cp_name', label: 'Contact Person', icon: 'UserIcon' },
        { key: 'cp_number', label: 'No Hp CP', icon: 'PhoneIcon' },
        { key: 'cp_email', label: 'Email CP', icon: 'MailIcon' },
        {
          key: 'created_at',
          label: 'Tanggal Register',
          icon: 'CalendarIcon',
          type: 'date',
        },
        { key: 'updated_at', label: 'Persetujuan', icon: 'CalendarIcon', type: 'date' },
      ],
      approval_status: null,
      setting,
    }
  },
  methods: {
    confirmBlokir() {
      const id = this.$route.params.id
      this.$bvModal
        .msgBoxConfirm('Are you sure blokir bengkel ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if (confirm) {
            this.$http
              .put(this.dataurl + '/' + id + '/active', { is_active: false })
              .then(() => {
                this.$router.push({ name: this.baseroute })
              })
          }
        })
    },

    showData(result) {
      this.$emit('readData', result)
      this.model = result
      this.approval_status = result.approval_status
    },
    confirmApproval(approve) {
      const id = this.$route.params.id
      let params = {
        approval_status: 2,
        approval_note: 'ditolak',
      }

      if (approve) {
        params.approval_status = 1
        params.approval_note = 'diterima'
      }

      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if (confirm) {
            this.$http.put(this.dataurl + '/' + id + '/approval', params).then(() => {
              this.$refs.baseinfo.fetchData()
            })
          }
        })
    },
  },
}
</script>
