<template lang="">
  <b-card title="Daftar Vendor Afiliasi">
    <basetable
      ref="basetable"
      :dataurl="dataurl+'/'+this.$route.params.id+'/vendor'"
      :fields="fields"
      :baseroute="baseroute"
      :is-delete="false"
      :is-edit="false"
      :is-add="false"
    >
      <template #cell(is_active)="data">
        <span
          class="badge "
          :class="data.item.is_active?'bg-primary':'bg-warning'"
        >
          {{ data.item.is_active?'Terdaftar':'Pengajuan' }}
        </span>
      </template>
    </basetable>
  </b-card>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      dataurl:"/workshop",
      baseroute:"vendor",
      fields: [
        { key: 'code', label: 'Kode', sortable: true },
        { key: 'name', label: 'Nama Vendor', sortable: true },
        { key: 'address', label: 'Alamat', sortable: true },
        { key: 'city', label: 'Kota', sortable: true },
        { key: 'phone', label: 'Telephone', sortable: true },
        { key: 'is_active', label: 'Status'}
      ],
    }
  },
}
</script>
<style lang="">

</style>
